import React from "react"
import { Link } from "gatsby"
import Button from "../components/button"

import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import meetupsData from "../../content/data/meetup-events"
class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Meetup Events" />
        <h1>
          Meetup Events{" "}
          <span role="img" aria-label="handshake emoji">
            🤝
          </span>
        </h1>
        <div style={{ marginLeft: `20px` }}>
          {meetupsData.meetups.map((data, index) => {
            return (
              <p>
                <h4>{data.month}</h4>
                <ul>
                  {data.events.map((event, index) => {
                    return (
                      <li key={`content_item_${index}`}>
                        <a
                          href={event.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {event.name}
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </p>
            )
          })}
        </div>
        <Link to="/">
          <Button marginTop="35px">Go to Home</Button>
        </Link>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
